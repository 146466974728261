import {configureStore} from '@reduxjs/toolkit';
import MenuSlice from './redux/menuSlice';

export const store = configureStore({
    reducer: {
        menu: MenuSlice,
    },
});

export type RootState = ReturnType<typeof store.getState>;
