import React, {FC, useEffect, useState} from "react";
import {ScrollView, StyleSheet, Text, View} from "react-native-web";
import {vw} from "../../../utils/tool/vw";
import {ReactComponent as Hotel} from "../../../assets/icons/hotel.svg";
import {ReactComponent as Restaurant} from "../../../assets/icons/restaurant.svg";
import {ReactComponent as Phone} from "../../../assets/icons/phone-filled-black.svg";
import {ReactComponent as Distance} from "../../../assets/icons/distance.svg";
import {ReactComponent as Global} from "../../../assets/icons/global.svg";
import {ReactComponent as Arrow} from "../../../assets/icons/chevron.svg";
import {ReactComponent as ArrowTop} from "../../../assets/icons/chevron-top.svg";
import {ReactComponent as UberEasts} from "../../../assets/icons/uber-logo.svg";
import {ReactComponent as GrubNub} from "../../../assets/icons/grubhub.svg";
import {ReactComponent as Resy} from "../../../assets/icons/resy.svg";
import {ReactComponent as Yelp} from "../../../assets/icons/yelp.svg";
import {ReactComponent as Doordash} from "../../../assets/icons/doordash.svg";
import {ReactComponent as Sauce} from "../../../assets/icons/sauce.svg";
import {ReactComponent as OpenTable} from "../../../assets/icons/openTable.svg";

import {$darkGray, $gray, $midnightBlack, $midnightGray, $white} from "../../constants/style";
import styles from "./HeaderMenu.module.scss";
import {setActiveCategory, startLoading, stopLoading} from "../../../store/redux/menuSlice";
import {useAppDispatch, useAppSelector} from "../../../utils/hook/redux-hooks";
import {animated, useSpring} from "@react-spring/web";
import moment from 'moment-timezone';
import {Collapse} from "@mui/material";

type Item = {
    title: string;
    active: boolean;
    icon?: FC<any>;
};

interface HeaderMenuProps {
    handleFilter?: (title: string) => void;
}

type WorkingHours = {
    id: number;
    mondayStart: string;
    mondayEnd: string;
    tuesdayStart: string;
    tuesdayEnd: string;
    wednesdayStart: string;
    wednesdayEnd: string;
    thursdayStart: string;
    thursdayEnd: string;
    fridayStart: string;
    fridayEnd: string;
    saturdayStart: string;
    saturdayEnd: string;
    sundayStart: string;
    sundayEnd: string;
};


export const HeaderMenu: FC<HeaderMenuProps> = ({handleFilter}) => {
    const {menu, activeCategory} = useAppSelector((state) => state.menu);
    const workingHours = menu?.workingHours ? menu.workingHours : null;
    const partners = menu?.partners ? menu.partners : null;
    const dispatch = useAppDispatch();

    const [items, setItems] = useState<Item[]>([]);
    const [isOpen, setIsOpen] = useState(true);
    const [status, setStatus] = useState('');
    const [openCloseTime, setOpenCloseTime] = useState('');
    const [locationOpen, setLocationOpen] = useState(false);
    const [collapsedTime, setCollapsedTime] = useState(false);

    const convertTo12HourFormat = (time: string, str: string = '') => {
        if (time === '00:00:00') {
            return '12:00 AM';
        }
        return moment(time, 'HH:mm:ss').format('h:mm A');
    };


    const getNextOpenDay = (dayIndex: number) => {
        const days = [
            'sunday',
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'saturday'
        ];

        for (let i = 1; i <= 7; i++) {
            const nextDayIndex = (dayIndex + i) % 7;
            const nextDay = days[nextDayIndex];
            const nextDayStart = workingHours[`${nextDay}Start`];
            const nextDayWorkingDay = workingHours[`${nextDay}WorkingDay`];

            if (nextDayWorkingDay !== false && nextDayStart) {
                return {day: nextDay, start: nextDayStart};
            }
        }

        return null;
    };

    const checkOpenStatus = () => {
        if (!workingHours) return;

        const now = moment.tz('America/New_York');
        const day = now.format('dddd').toLowerCase();
        const currentTime = now.format('HH:mm:ss');
        console.log('currentTime', currentTime);
        const dayIndex = now.day();

        const start = workingHours[`${day}Start`];
        console.log('start', start);
        const end = workingHours[`${day}End`];
        console.log('end', end);
        const isWorkingDay = workingHours[`${day}WorkingDay`];

        if (isWorkingDay === false) {
            setStatus('Closed');
            setOpenCloseTime('Closed today');
            setLocationOpen(false);
            return;
        }

        if (!start || !end) {
            setStatus('Closed');
            setOpenCloseTime('No working hours available');
            setLocationOpen(false);
            return;
        }

        const startTime = moment.tz(start, 'HH:mm:ss', 'America/New_York');
        let endTime = moment.tz(end, 'HH:mm:ss', 'America/New_York');

        if (endTime.isBefore(startTime)) {
            endTime.add(1, 'day');
        }

        if (now.isBetween(startTime, endTime)) {
            setStatus('Open');
            setOpenCloseTime(`Closes ${convertTo12HourFormat(end)}`);
            setLocationOpen(true);
        } else {
            const nextOpen = getNextOpenDay(dayIndex);
            console.log('nextOpen', nextOpen?.start, nextOpen?.day);
            if (nextOpen) {
                setStatus('Closed');
                setOpenCloseTime(`Opens ${convertTo12HourFormat(nextOpen.start)}`);
                setLocationOpen(false);
            } else {
                setStatus('Closed');
                setOpenCloseTime('No upcoming opening hours');
                setLocationOpen(false);
            }
        }
    };

    useEffect(() => {
        checkOpenStatus();
    }, [workingHours]);

    const formatTime = (start: string, end: string, isWorkingDay: boolean | null) => {
        if (isWorkingDay === false) {
            return 'Closed today';
        }
        if (start.toLowerCase() === 'closed' || end.toLowerCase() === 'closed') {
            return 'Closed';
        }
        return `${start} – ${end}`;
    };

    const daysOfWeek = workingHours ? [
        {
            name: "Sunday",
            start: convertTo12HourFormat(workingHours.sundayStart),
            end: convertTo12HourFormat(workingHours.sundayEnd),
            isWorkingDay: workingHours.sundayWorkingDay
        },
        {
            name: "Monday",
            start: convertTo12HourFormat(workingHours.mondayStart),
            end: convertTo12HourFormat(workingHours.mondayEnd),
            isWorkingDay: workingHours.mondayWorkingDay
        },
        {
            name: "Tuesday",
            start: convertTo12HourFormat(workingHours.tuesdayStart),
            end: convertTo12HourFormat(workingHours.tuesdayEnd),
            isWorkingDay: workingHours.tuesdayWorkingDay
        },
        {
            name: "Wednesday",
            start: convertTo12HourFormat(workingHours.wednesdayStart),
            end: convertTo12HourFormat(workingHours.wednesdayEnd),
            isWorkingDay: workingHours.wednesdayWorkingDay
        },
        {
            name: "Thursday",
            start: convertTo12HourFormat(workingHours.thursdayStart),
            end: convertTo12HourFormat(workingHours.thursdayEnd),
            isWorkingDay: workingHours.thursdayWorkingDay
        },
        {
            name: "Friday",
            start: convertTo12HourFormat(workingHours.fridayStart, "Friday"),
            end: convertTo12HourFormat(workingHours.fridayEnd, "Friday"),
            isWorkingDay: workingHours.fridayWorkingDay
        },
        {
            name: "Saturday",
            start: convertTo12HourFormat(workingHours.saturdayStart),
            end: convertTo12HourFormat(workingHours.saturdayEnd),
            isWorkingDay: workingHours.saturdayWorkingDay
        },
    ] : [];


    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const animatedStyle = useSpring({
        height: isOpen ? vw(600) : vw(80),
        overflow: "hidden",
    });

    useEffect(() => {
        if (menu?.categories) {
            const categories = menu.categories.map((category, index) => ({
                title: category.name,
                active: activeCategory ? category.name === activeCategory : index === 0,
            }));
            setItems(categories);
            if (!activeCategory) {
                dispatch(setActiveCategory(categories[0].title));
            }
        }
    }, [menu]);

    useEffect(() => {
        if (menu) {
            setItems((prevItems) =>
                prevItems.map((item) => {
                    if (item.title === activeCategory) {
                        return {...item, active: true};
                    }
                    return {...item, active: false};
                })
            );
        }
    }, [activeCategory]);

    const handleItemPress = async (title: string) => {
        dispatch(startLoading());
        setItems((prevItems) =>
            prevItems.map((item) => {
                if (item.title === title && !item.active) {
                    return {...item, active: true};
                }
                return {...item, active: false};
            })
        );

        setTimeout(() => {
            dispatch(setActiveCategory(title));
            dispatch(stopLoading());
        }, 100);
    };

    if (!menu) return null;

    const handleLinkClick = (url: string | null) => {
        if (!url) return;
        window.location.href = url;
    };

    const handlePhoneClick = (phone: string | null) => {
        if (!phone) return;
        window.location.href = `tel:${phone}`;
    };

    const handleMapClick = (address: string) => {
        const encodedAddress = encodeURIComponent(address);
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

        if (isIOS) {
            window.location.href = `https://maps.apple.com/?q=${encodedAddress}`;
        } else {
            window.location.href = `geo:0,0?q=${encodedAddress}`;
        }
    };

    return (
        <div className={styles.container}>
            <View style={inlineStyles.container}>
                {
                    menu?.locationVideo && menu?.partners && menu?.website && menu?.address && menu?.phoneNumber && menu?.workingHours.length !== 0 ? (
                        <>
                            <animated.div style={animatedStyle} className={styles.animatedContainer}>
                                <div className={styles.restaurantCard}>
                                    <div className={styles.videoWrapper}>
                                        <video
                                            // @ts-ignore
                                            src={menu?.locationVideo[0].url}
                                            autoPlay
                                            muted
                                            loop
                                            className={styles.video}
                                            playsInline
                                        >
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                    <div className={!collapsedTime ? styles.gradient : styles.gradientOpenTime}/>
                                    <div className={styles.headerMenu}>
                                        <div className={styles.headerTitle}>
                                            {menu?.establishmentType === "restaurant" ? (
                                                <Restaurant width={vw(35)} height={vw(35)}/>
                                            ) : (
                                                <Hotel width={vw(35)} height={vw(35)}/>
                                            )}
                                            <Text style={inlineStyles.headerMenuTitle}>{menu?.name}</Text>
                                        </div>
                                        <div className={styles.locationInfoBlock}>
                                            <div onClick={() => setCollapsedTime((prev) => !prev)}
                                                 style={{
                                                     marginBottom: 15,
                                                     zIndex: 15,
                                                     gap: vw(7),
                                                     alignItems: 'center',
                                                     display: 'flex'
                                                 }}>
                                                <div>
                                                    <Text style={inlineStyles.ItemText}>{openCloseTime}</Text>
                                                    <Text
                                                        style={[inlineStyles.ItemText, locationOpen ? {color: '#95B35D'} : {color: '#B35D5D'}]}> ({status})</Text>
                                                </div>
                                                {
                                                    !collapsedTime ? <ArrowTop width={vw(16)} height={vw(16)}/> :
                                                        <Arrow width={vw(16)} height={vw(16)}/>
                                                }
                                            </div>
                                            <Collapse key={'workingHours'} in={collapsedTime}
                                                      style={{marginBottom: vw(10)}}>

                                                <div
                                                    style={{
                                                        flexDirection: 'column',
                                                        display: 'flex',
                                                        width: '68%',
                                                        gap: vw(5)
                                                    }}
                                                    onClick={() => setCollapsedTime(false)}
                                                >
                                                    {daysOfWeek.map(day => (
                                                        <div className={styles.time}>
                                                            <div key={day.name}>
                                                                {day.name}
                                                            </div>
                                                            <div>
                                                                {formatTime(day.start, day.end, day.isWorkingDay)}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </Collapse>
                                            <div>
                                                <ScrollView contentContainerStyle={{
                                                    marginBottom: 24,
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    gap: vw(16)
                                                }} horizontal showsHorizontalScrollIndicator={false}
                                                            showsVerticalScrollIndicator={false}>
                                                    {partners?.UberEasts && (
                                                        <div
                                                            className={partners?.UberEastsLink && styles.touchableOpacity}
                                                            onClick={() => handleLinkClick(partners?.UberEastsLink)}>
                                                            <UberEasts width={vw(61)} height={vw(10)}/>
                                                        </div>
                                                    )}
                                                    {partners?.GrubNub && (
                                                        <div
                                                            className={partners?.GrubNubLink && styles.touchableOpacity}
                                                            onClick={() => handleLinkClick(partners?.GrubNubLink)}>
                                                            <GrubNub width={vw(61)} height={vw(10)}/>
                                                        </div>
                                                    )}
                                                    {partners?.Resy && (
                                                        <div className={partners?.ResyLink && styles.touchableOpacity}
                                                             onClick={() => handleLinkClick(partners?.ResyLink)}>
                                                            <Resy width={vw(61)} height={vw(10)}/>
                                                        </div>
                                                    )}
                                                    {partners?.Yelp && (
                                                        <div className={partners?.YelpLink && styles.touchableOpacity}
                                                             style={{
                                                                 position: 'relative',
                                                                 height: vw(10),
                                                                 width: vw(61),
                                                                 zIndex: 29
                                                             }}
                                                             onClick={() => handleLinkClick(partners?.YelpLink)}>
                                                            <div style={{position: 'absolute', top: -5, zIndex: 30}}>
                                                                <Yelp width={vw(61)} height={vw(20)}/>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {partners?.Sauce && (
                                                        <div className={partners?.SauceLink && styles.touchableOpacity}
                                                             onClick={() => handleLinkClick(partners?.SauceLink)}>
                                                            <Sauce width={vw(61)} height={vw(10)}/>
                                                        </div>
                                                    )}
                                                    {partners?.Doordash && (
                                                        <div
                                                            className={partners?.DoordashLink && styles.touchableOpacity}
                                                            onClick={() => handleLinkClick(partners?.DoordashLink)}>
                                                            <Doordash width={vw(90)} height={vw(15)}/>
                                                        </div>
                                                    )}
                                                    {partners?.openTable && (
                                                        <div
                                                            className={partners?.openTableLink && styles.touchableOpacity}
                                                            style={{
                                                                position: 'relative',
                                                                height: vw(10),
                                                                width: vw(61),
                                                                zIndex: 29
                                                            }}
                                                            onClick={() => handleLinkClick(partners?.openTableLink)}>
                                                            <div style={{position: 'absolute', top: 0, zIndex: 30}}>
                                                                <OpenTable width={vw(90)} height={vw(15)}/>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div/>
                                                    <div/>
                                                </ScrollView>

                                            </div>
                                            <div className={styles.communicationButtons}>
                                                <div style={inlineStyles.ItemCall}
                                                     onClick={() => handlePhoneClick(menu.phoneNumber)}>
                                                    <Phone width={vw(14)} height={vw(14)}/>
                                                    <Text
                                                        style={[inlineStyles.ItemText, {color: $midnightBlack}]}>Call</Text>
                                                </div>
                                                <div style={inlineStyles.communicationButton}
                                                     onClick={() => handleMapClick(menu.address)}>
                                                    <Distance width={vw(14)} height={vw(14)}/>
                                                    <Text
                                                        style={[inlineStyles.ItemText, {color: $gray}]}>Directions</Text>

                                                </div>
                                                {
                                                    menu.website && (
                                                        <div style={inlineStyles.communicationButton}
                                                             onClick={() => handleLinkClick(menu.website)}>
                                                            <Global width={vw(14)} height={vw(14)}/>
                                                            <Text
                                                                style={[inlineStyles.ItemText, {color: $gray}]}>Website</Text>

                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                ы
                                <div className={styles.buttonContainer} onClick={toggleOpen}>
                                    <div className={styles.btn}>
                                        <Text style={[inlineStyles.ItemText]}
                                        >{isOpen ? "Hide info" : "Read more"}</Text>
                                        {
                                            isOpen ? <ArrowTop width={vw(16)} height={vw(16)}/> :
                                                <Arrow width={vw(16)} height={vw(16)}/>
                                        }
                                    </div>

                                </div>
                            </animated.div>
                            {
                                !isOpen && <div className={`${styles.gradientBlack} ${styles.show}`}/>
                            }
                        </>
                    ) : (
                        <View style={[inlineStyles.headerMenu]}>
                            {menu?.establishmentType === 'restaurant' ? (
                                <Restaurant width={20} height={20}/>
                            ) : (
                                <Hotel width={20} height={20}/>
                            )}
                            <Text style={inlineStyles.headerMenuTitle}>{menu?.name}</Text>
                            <View></View>
                        </View>
                    )
                }
                <ScrollView
                    horizontal
                    showsHorizontalScrollIndicator={false}
                    showsVerticalScrollIndicator={false}
                    style={{maxWidth: "103%", marginLeft: vw(5), zIndex: 40}}
                >
                    {items.map((item, index) => {
                        return (
                            <View
                                key={index}
                                style={{...inlineStyles.item, ...(item.active ? inlineStyles.activeItem : {})}}
                                onClick={() => handleItemPress(item.title)}
                            >
                                <Text style={[inlineStyles.ItemText, item.active ? inlineStyles.ActiveItemText : {}]}>
                                    {item.title}
                                </Text>
                            </View>
                        );
                    })}
                </ScrollView>
            </View>
        </div>
    );
};

const inlineStyles = StyleSheet.create({
    container: {
        width: "100%",
        flex: 1,
        display: "flex",
        color: $white,
        flexDirection: "column",
        justifyContent: "space-between",
        marginLeft: vw(-4),
        position: "relative",
    },
    headerMenu: {
        paddingLeft: vw(20),
        paddingTop: vw(15),
        paddingBottom: vw(20),
        display: "flex",
        flexDirection: "row",
        gap: vw(10),
    },
    headerMenuTitle: {
        color: $white,
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: vw(22),
        letterSpacing: -0.32,
    },
    item: {
        display: "flex",
        flexDirection: "row",
        gap: vw(4),
        color: $white,
        padding: vw(8),
        paddingLeft: vw(12),
        paddingRight: vw(12),
        justifyContent: "center",
        alignItems: "center",
        marginHorizontal: vw(3),
        borderRadius: 999,
        backgroundColor: $darkGray,
    },
    activeItem: {
        backgroundColor: "#E6DCC8",
    },

    ItemText: {
        color: $white,
        fontSize: vw(15),
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: vw(20),
        letterSpacing: -0.24,
    },
    ActiveItemText: {
        color: $darkGray,
    },
    communicationButton: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: vw(8),
        paddingLeft: vw(12),
        paddingRight: vw(12),
        borderRadius: 999,
        backgroundColor: $midnightGray,
        gap: vw(5)
    },
    ItemCall: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: vw(8),
        paddingLeft: vw(12),
        paddingRight: vw(12),
        backgroundColor: $white,
        borderRadius: 999,
        gap: vw(5)
    }
});
