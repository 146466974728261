import React, {useEffect, useRef, useState} from "react";
import {HeaderMenu} from "../../common/ui-kit/headers/HeaderMenu";
// import { Indicator } from '../../common/ui-kit/Indicator';
import {IndicatorEnum} from "../../common/types/IndicatorEnum";
import {DietaryOption, DishInMenuType, DishType} from "../../common/types/ResponseType";
import styles from "./MenuPage.module.scss";
import {Indicator} from "../../common/ui-kit/Indicator";
import {useNavigate, useParams} from "react-router-dom";
import menuResponse from '../menuResponse.json'
import {Notification} from "../../common/ui-kit/Notification/Notification";
import {vw} from "../../utils/tool/vw";
import {$white} from "../../common/constants/style";
import Filter from '../../assets/icons/filter.svg'
import Chevron from '../../assets/icons/chevron.svg'
import {useAppDispatch, useAppSelector} from "../../utils/hook/redux-hooks";
import {fetchMenu} from "../../store/redux/menuSlice";
import {FilterModal} from "../../common/ui-kit/Modal/FilterModal";
import {Loader} from "../../common/ui-kit/Loader/Loader";
import {formatPrice} from "../../utils/tool/format";
import Analytics from "../../analytics/Analytics";

type CategoryDishesType = Record<string, string[]>;
type CategorizedDishes = {
    [category: string]: DishInMenuType[];
};

interface MenuPageProps {
    updateMenuId: (menuId: string | null) => void;
}

export const MenuPage: React.FC<MenuPageProps> = ({updateMenuId}) => {
    const [originalMenu, setOriginalMenu] = useState<any>(menuResponse);
    const [modalVisible, setModalVisible] = useState(false);
    const {id} = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {menu, activeDietaries, activeCategory, loading, categoryLoading} = useAppSelector(state => state.menu);
    const [isDishClicked, setIsDishClicked] = useState<boolean>(() => {
        const storedValue = localStorage.getItem('isDishClicked');
        return storedValue ? JSON.parse(storedValue) : false;
    });

    const startTimeRef = useRef<number>(Date.now());


    const [categorizedDishes, setCategorizedDishes] = useState<CategorizedDishes>({});


    useEffect(() => {
        if (id) {
            updateMenuId(id.toString());
            dispatch(fetchMenu({id: Number(id)}));
        } else {
            // navigate.navigate(ScreenEnum.SCANNER);
        }
    }, [updateMenuId]);


    const handleClickCard = (dishId: number) => {
        setIsDishClicked(true);
        localStorage.setItem('isDishClicked', JSON.stringify(true));
        navigate(`/menu/${id}/dish/${dishId}`);
    };

    useEffect(() => {
        if (menu?.dishes) {
            const categories = {};

            menu.dishes.forEach(dish => {
                const category = dish?.category?.name;
                // @ts-ignore
                if (!categories[category]) {
                    // @ts-ignore
                    categories[category] = [];
                }
                // @ts-ignore
                categories[category].push(dish);
            });

            setCategorizedDishes(categories);
        }
    }, [menu]);


    const renderItem = (item: DishInMenuType, isFirstDish: boolean) => {

        return (
            <div onClick={() => handleClickCard(item.id)} className={styles.card}>
                <img src={item?.image?.url} alt={item?.name} className={styles.image}/>
                {isFirstDish && (
                    <div className={styles.overlay}>
                        <span className={styles.overlayText}>Tap for details</span>
                    </div>
                )}
                <div className={styles.cardContainer}>
                    <div className={styles.headerContainer}>
                        <span className={styles.headerText}>{item?.name}</span>
                        {/*<div className={styles.dietaries}>*/}
                        {/*    {item?.dietary?.map((dietary: DietaryOption, index: number) => {*/}
                        {/*        return <Indicator*/}
                        {/*            key={index}*/}
                        {/*            name={IndicatorEnum.MENU}*/}
                        {/*            dietary={dietary.dietary}*/}
                        {/*        />*/}
                        {/*    })}*/}
                        {/*</div>*/}
                    </div>
                    <div className={styles.containerBottom}>
                        <Indicator name={IndicatorEnum.PRICE} text={formatPrice(item.price)}/>
                        <Indicator text={activeCategory == 'New' ? "Pasta" : activeCategory}/>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            {/*<Notification/>*/}
            <div className={styles.container}>
                <HeaderMenu/>
                <div className={styles.controlBlock}>
                    {/*<div onClick={() => setModalVisible(true)} className={styles.filterButton}>*/}
                    {/*    <>*/}
                    {/*        /!*<Filter width={vw(16)} height={vw(16)}/>*!/*/}
                    {/*        <img src={Filter} alt={'Filter'}/>*/}
                    {/*        <div*/}
                    {/*            style={{*/}
                    {/*                color: $white,*/}
                    {/*                fontSize: vw(12)*/}
                    {/*            }}>Filters*/}
                    {/*        </div>*/}
                    {/*        /!*<Chevron width={vw(12)} height={vw(12)}/>*!/*/}
                    {/*        <img alt={'Chevron'} src={Chevron}/>*/}
                    {/*    </>*/}
                    {/*</div>*/}
                </div>
                <div className={styles.content}>
                    {
                        loading || categoryLoading || !activeCategory ? (
                            <div className={styles.loader}>
                                <Loader/>
                            </div>
                        ) : (
                            categorizedDishes[activeCategory]?.map((dish: DishInMenuType, index: number) => (
                                <React.Fragment key={index}>
                                    {renderItem(dish, index === 0 && !isDishClicked)}
                                </React.Fragment>
                            ))
                        )
                    }
                </div>
            </div>
            {/*<FilterModal modalVisible={modalVisible} setModalVisible={setModalVisible}/>*/}

        </>
    );
};


