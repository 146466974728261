import {Animated, Image, Pressable, StyleSheet, Text, View} from "react-native-web";
// import { LinearGradient } from 'expo-linear-gradient';
import {vw} from "../../../../utils/tool/vw";
import {ReactComponent as List} from "../../../../assets/icons/list.svg";
import {ReactComponent as Like} from "../../../../assets/icons/heart-outline.svg";
import {ReactComponent as ArrowBack} from "../../../../assets/icons/carret.left.svg";
import React, {FC, useRef, useState} from "react";
import {$semiTransparentWhite, $white} from "../../../constants/style";
import {InformationDies} from "../../../ui-kit/InformationDies";
import {DishType} from "../../../types/ResponseType";
import style from "./SingleDishVideo.module.scss";
import {useNavigate} from "react-router-dom";
import {Modal} from "../../../ui-kit/Modal/Modal";
import {useAppSelector} from "../../../../utils/hook/redux-hooks";
import {Loader} from "../../../ui-kit/Loader/Loader";
import {formatPrice} from "../../../../utils/tool/format";


type IProps = {
    selectedDish: DishType | null;
    handleScrollToCookingProcess?: () => void;
    handleScrollToIngredients?: () => void;
};
export const SingleDishVideo: FC<IProps> = ({
                                                selectedDish,
                                                handleScrollToIngredients,
                                                handleScrollToCookingProcess
                                            }) => {
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    // const { selectedDish } = useAppSelector((state) => state.dish);
    const [expanded, setExpanded] = useState(false);
    const [numberOfLines, setNumberOfLines] = useState(1);
    const animationHeight = useRef(new Animated.Value(20)).current;
    const {loading} = useAppSelector(state => state.menu);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handlePress = () => {
        setExpanded(!expanded);
        setNumberOfLines(expanded ? 1 : 40);
        Animated.timing(animationHeight, {
            toValue: expanded ? vw(40) : vw(300),
            duration: 600,
            useNativeDriver: false
        }).start();
    };

    if (loading) return <View style={{
        width: "100%",
        height: vw(660),
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
    }}><Loader/></View>

    return (
        <View style={styles.videoWrapper}>
            <video
                src={selectedDish?.video ? selectedDish.video.url : ""}
                autoPlay
                muted
                loop
                style={{width: "100%", position: "absolute"}}
                playsInline
            >
                Your browser does not support the video tag.
            </video>
            <Pressable
                onPress={() => navigate(-1)}
                style={{position: "absolute", top: vw(13), left: vw(13), padding: vw(10)}}
            >
                <ArrowBack width={vw(24)} height={vw(24)}/>
            </Pressable>
            <div
                className={style.container} style={expanded ? {height: '100vh'} : {}}>
                <View style={styles.videoInfo}>
                    <View>
                        <Text style={styles.videoHeader}>{selectedDish?.name}</Text>
                        <Pressable onPress={handlePress}>
                            <Animated.View style={{maxHeight: animationHeight}}>
                                <Animated.Text
                                    style={[styles.videoDescription, {maxHeight: animationHeight}]}
                                    numberOfLines={40}
                                    ellipsizeMode="tail">
                                    {selectedDish?.description}
                                </Animated.Text>
                            </Animated.View>
                        </Pressable>
                    </View>
                    <InformationDies
                        price={formatPrice(selectedDish?.price || null)}
                        // rating={selectedDish?.rating}
                        time={selectedDish?.time}
                    />
                </View>
                <View style={styles.interaction}>
                    <Pressable onPress={handleScrollToIngredients}>
                        <List width={vw(38)} height={vw(38)}/>
                    </Pressable>

                    <Pressable onPress={handleScrollToCookingProcess}>
                        <Image
                            source={require("../../../../assets/icons/chef.png")}
                            style={{width: vw(24), height: vw(24)}}
                        />
                    </Pressable>
                </View>
            </div>
            {/*<Modal open={openModal} setOpenModal={setOpenModal} header={"Unlock Favorites"}*/}
            {/*       title={"Unlock the joy of saving your favorites. Download the app now!"}/>*/}
        </View>
    );
};

const styles = StyleSheet.create({
    videoWrapper: {
        width: "100%",
        height: vw(660),
        borderRadius: vw(32),
        overflow: "hidden",
        position: "relative"
    },
    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        position: "absolute",
        alignItems: "flex-end",
        bottom: 0,
        left: 0,
        width: "100%",
        height: vw(290),
        paddingHorizontal: vw(16),
        paddingBottom: vw(24),
        color: $white
    },
    videoInfo: {
        display: "flex",
        flexDirection: "column",
        gap: vw(16)
    },
    headerVideo: {
        display: "flex",
        flexDirection: "row",
        gap: vw(4),
        alignItems: "center"
    },
    headerVideoLocation: {
        gap: vw(8)
    },
    locationText: {
        color: $white,
        textAlign: "center",
        fontSize: vw(15),
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: vw(20),
        letterSpacing: -0.24
    },
    menuDies: {
        display: "flex",
        padding: vw(4),
        paddingHorizontal: vw(10),
        alignItems: "flex-start",
        marginHorizontal: vw(4),
        borderRadius: 999,
        borderWidth: vw(1),
        borderColor: $semiTransparentWhite
    },
    menuDies_text: {
        color: $white,
        fontSize: vw(13),
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: vw(18),
        letterSpacing: -0.078
    },
    videoHeader: {
        color: $white,
        fontSize: vw(17),
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: vw(22),
        letterSpacing: -0.408,
        marginBottom: vw(8)
    },
    videoDescription: {
        color: $white,
        fontSize: vw(13),
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: vw(18),
        letterSpacing: -0.078,
        maxWidth: vw(285)
    },
    interaction: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: vw(18),
        marginBottom: vw(8)
    },
    likes_text: {
        fontSize: vw(11),
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: vw(13),
        letterSpacing: vw(0.066),
        color: $white
    }
});
