import { StyleSheet, View } from 'react-native-web';

import { FC } from 'react';
import { vw } from "../../utils/tool/vw";
import { ScreenEnum } from "../enum/ScreenEnum";
import { $semiTransparentGray, $SFProText400, $white } from "../constants/style";
import { Indicator } from "./Indicator";
import { IndicatorEnum } from "../types/IndicatorEnum";

type InformationDiesPropsType = {
    price?: string | null;
    rating?: number;
    time?: string
};
export const InformationDies: FC<InformationDiesPropsType> = ({
    price,
    rating,
    time,
}) => {
   const route = { name: ScreenEnum.RESTAURANT_LIST }

    return (
        <View style={{ display: 'flex', flexDirection: 'row' }}>
            {price && <Indicator name={IndicatorEnum.PRICE} text={price} />}
            {rating &&  <Indicator name={IndicatorEnum.RATING} text={rating?.toFixed(1).toString()} />}
            {
                time &&
                <Indicator
                    name={IndicatorEnum.TIME}
                    text={time.includes('-')
                        ? time.split('-').map(t => `${parseFloat(t)?.toFixed(0)} min`).join(' - ')
                        : `${parseFloat(time)?.toFixed(0)} min`}
                />
            }

        </View>
    );
};

const styles = StyleSheet.create({
    informationDies: {
        display: 'flex',
        flexDirection: 'row',
        gap: vw(4),
        padding: vw(4),
        paddingHorizontal: vw(10),
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: 999,
        borderWidth: vw(1),
        borderColor: '#444',
        backgroundColor: $semiTransparentGray,
        marginRight: vw(6),
        color: $white,
    },
    informationDies_text: {
        overflow: 'hidden',
        color: $white,
        fontFamily: $SFProText400,
        fontSize: vw(12),
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: vw(16),
    },
});
