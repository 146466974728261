import { Route, Routes } from "react-router-dom";
import { MenuPage } from "../../../pages/MenuPage/MenuPage";
import { SingleDishPage } from "../../../pages/SingleDishPage/SingleDishPage";
import Analytics from "../../../analytics/Analytics";
import {useEffect, useRef} from "react";

export const AppRoutes = () => {
    const startTimeRef = useRef<number>(Date.now());
    const currentMenuIdRef = useRef<string | null>(null);


    useEffect(() => {
        const handleBeforeUnload = () => {
            if (currentMenuIdRef.current) {
                const duration = Date.now() - startTimeRef.current;
                Analytics.logMenuVisit(currentMenuIdRef?.current?.toString() ?? '', duration);

            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            if (currentMenuIdRef.current) {
                const duration = Date.now() - startTimeRef.current;
                Analytics.logMenuVisit(currentMenuIdRef?.current?.toString() ?? '', duration);
            }
        };
    }, []);

    const updateMenuId = (menuId: string | null) => {
        currentMenuIdRef.current = menuId;
        if (!startTimeRef.current) {
            startTimeRef.current = Date.now();
        }
    };

  return (
      <Routes>
          <Route path="/menu/:id" element={<MenuPage updateMenuId={updateMenuId} />}/>
          <Route path="/menu/:id/dish/:dishId" element={<SingleDishPage />} />
      </Routes>
  )
};
