import {StyleSheet, Text, View} from "react-native-web";
import {ReactComponent as Dollar} from "../../assets/icons/dollar.svg";
import {ReactComponent as Distance} from "../../assets/icons/distance.svg";
import {ReactComponent as Dollars} from "../../assets/icons/dollars.svg";
import {ReactComponent as Star} from "../../assets/icons/star-filled.svg";
import {ReactComponent as MeatColor} from "../../assets/icons/menu/meat.svg";
import {ReactComponent as Vegetarian} from "../../assets/icons/menu/vegan-green.svg";
import {ReactComponent as NoGlutenColor} from "../../assets/icons/menu/no_gluten_filled.svg";
import {ReactComponent as NoLactoseColor} from "../../assets/icons/menu/no_milk_filled.svg";
import {ReactComponent as SpicyColor} from "../../assets/icons/menu/hot-filled.svg";
import {ReactComponent as FishColor} from "../../assets/icons/menu/fish-filled.svg";
import {ReactComponent as Time} from "../../assets/icons/clock.svg";
import {ReactComponent as Vegan} from '../../assets/icons/menu/vegan.svg';
import {ReactComponent as Halal} from '../../assets/icons/menu/Halal.svg';
import {ReactComponent as Kosher} from '../../assets/icons/menu/Kosher.svg';
import {ReactComponent as SoyFree} from '../../assets/icons/menu/soy_free_filled.svg';
import {vw} from "../../utils/tool/vw";
import {IndicatorEnum} from "../types/IndicatorEnum";
import {$semiTransparentGray, $white} from "../constants/style";
import {MenuDietaryEnum} from "../enum/MenuDietaryEnum";

type Props = {
    name?: IndicatorEnum;
    text?: string | null;
    dietary?: MenuDietaryEnum;
};

export const Indicator = ({name, text, dietary}: Props) => {
    let Icon;


    const getIconColorByCategory = (features: MenuDietaryEnum | '') => {
        switch (features) {
            case MenuDietaryEnum.MEAT:
                return <MeatColor width={vw(18)} height={vw(18)}/>;
            case MenuDietaryEnum.NO_GLUTEN:
                return <NoGlutenColor width={vw(18)} height={vw(18)}/>;
            case MenuDietaryEnum.NO_LACTOSE:
                return <NoLactoseColor width={vw(18)} height={vw(18)}/>;
            case MenuDietaryEnum.SPICY:
                return <SpicyColor width={vw(18)} height={vw(18)}/>;
            case MenuDietaryEnum.VEGAN:
                return <Vegan width={vw(18)} height={vw(18)}/>;
            case MenuDietaryEnum.FISH:
                return <FishColor width={vw(18)} height={vw(18)}/>;
            case MenuDietaryEnum.VEGETARIAN:
                return <Vegetarian width={vw(18)} height={vw(18)}/>;
            case MenuDietaryEnum.HALAL:
                return <Halal width={vw(18)} height={vw(18)}/>;
            case MenuDietaryEnum.KOSHER:
                return <Kosher width={vw(18)} height={vw(18)}/>;
            case MenuDietaryEnum.SOY_FREE:
                return <SoyFree width={vw(18)} height={vw(18)}/>;
            default:
                return null;
        }
    };

    const getIconByCategory = (feature: MenuDietaryEnum | '') => {
        switch (feature) {
            case MenuDietaryEnum.MEAT:
                return <MeatColor width={vw(16)} height={vw(16)}/>;
            case MenuDietaryEnum.NO_GLUTEN:
        }
    };

    switch (name) {
        case IndicatorEnum.DISTANCE: {
            Icon = <Distance width={vw(12)} height={vw(12)}/>;
            break;
        }
        case IndicatorEnum.PRICE: {
            Icon = <Dollar width={vw(12)} height={vw(12)}/>;
            break;
        }
        case IndicatorEnum.PRICE_THREE_DOLLARS: {
            Icon = <Dollars width={vw(23)} height={vw(16)}/>;
            break;
        }
        // case IndicatorEnum.CATEGORY: {
        //     Icon = <Vegan width={vw(12)} height={vw(12)} />;
        //     break;
        // }
        case IndicatorEnum.RATING: {
            Icon = <Star width={vw(12)} height={vw(12)}/>;
            break;
        }
        case IndicatorEnum.MENU: {
            Icon = getIconColorByCategory(dietary ? dietary : '');
            break;
        }
        case IndicatorEnum.TIME: {
            Icon = <Time width={vw(12)} height={vw(12)}/>;
            break;
        }
        case IndicatorEnum.RESTAURANT: {
            Icon = getIconByCategory(dietary ? dietary : '');
            break;
        }
        default:
            Icon = null;
    }
    if(!text) return null;
    return (
        <View style={[name === IndicatorEnum.MENU ? styles.informationDiesFeature : styles.informationDies]}>
            {Icon}
            {text && <Text style={styles.informationDies_text}>{text}</Text>}
        </View>
    );
};

const styles = StyleSheet.create({
    informationDies: {
        display: 'flex',
        flexDirection: 'row',
        gap: vw(4),
        paddingVertical: vw(4),
        paddingHorizontal: vw(10),
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: 999,
        borderWidth: vw(1),
        borderColor: '#444',
        backgroundColor: $semiTransparentGray,
        marginRight: vw(4),
        color: $white,
    },
    informationDiesFeature: {
        display: 'flex',
        flexDirection: 'row',
        gap: vw(4),
        padding: vw(4),
        alignItems: 'center',
        justifyContent: 'space-between',
        color: $white,
    },
    informationDies_text: {
        overflow: 'hidden',
        color: $white,
        // fontFamily: $SFProText400,
        fontSize: vw(12),
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: vw(16),
    },
});
