import React, {FC} from 'react';
import {StyleSheet, View} from 'react-native-web';

const darkenColor = (hexColor: string, alpha: number = 0.30) => {
    let r = parseInt(hexColor.substr(1, 2), 16),
        g = parseInt(hexColor.substr(3, 2), 16),
        b = parseInt(hexColor.substr(5, 2), 16);

    r = Math.floor(r * 0.7);
    g = Math.floor(g * 0.7);
    b = Math.floor(b * 0.7);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};


interface IProps {
    filledPercentage: number;
    color: string;
}

export const VerticalScale: FC<IProps> = ({filledPercentage, color}) => {
    const scaleHeight = 40;
    const filledHeight = scaleHeight * filledPercentage / 100;
    const unfilledColor = darkenColor(color);

    const styles = StyleSheet.create({
        scaleContainer: {
            width: 6,
            height: scaleHeight,
            backgroundColor: unfilledColor,
            borderRadius: 3,
            justifyContent: "flex-end",
        },
        filledPart: {
            width: 6,
            height: filledHeight,
            backgroundColor: color,
            borderRadius: 3,
        }
    });

    return (
        <View style={styles.scaleContainer}>
            <View style={styles.filledPart}/>
        </View>
    );


};
