export const formatPrice = (price: string | null) => {
    if (!price) {
        return null;
    }
    const prices = price.split('/').map(p => p.trim());

    if (!prices.every(p => !isNaN(parseFloat(p)))) {
        return null;
    }
    const formattedPrices = prices.map(p => parseFloat(p).toFixed(2));
    return formattedPrices.join(' / ');
}
