import React from 'react';
import './Loader.scss';

export const Loader = () => {
    return (
        <div className="group">
            <div className="frame">
                <div className="ellipse"></div>
                <div className="div"></div>
                <div className="ellipse2"></div>
                <div className="ellipse3"></div>
            </div>
        </div>
    );
};
