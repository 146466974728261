import React, {useEffect, useRef, useState} from "react";
import {StyleSheet, View} from "react-native-web";
import {$black} from "../../common/constants/style";
import {vw} from "../../utils/tool/vw";
import {SingleDishVideo} from "../../common/component/HomeScreen/FullDishVideo/SingleDishVideo";
import {Ingredients} from "../../common/component/HomeScreen/Ingredients/Ingredients";
import {BlockedPage} from "../../common/component/HomeScreen/BlockedPage/BlockedPage";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../utils/hook/redux-hooks";
import {fetchDishById} from "../../store/redux/menuSlice";
import {DishType} from "../../common/types/ResponseType";
import {NutritionValue} from "../../common/component/HomeScreen/NutritionValue/NutritionValue";
import {FunFactComponent} from "../../common/component/HomeScreen/FunFact/FunFactCard";
import Analytics from "../../analytics/Analytics";
import {RootState} from "../../store/store";

export const SingleDishPage = () => {
    const {dishId} = useParams();
    const dispatch = useAppDispatch();
    const {selectedDish, menu, activeCategory} = useAppSelector((state: RootState) => state.menu);

    useEffect(() => {
        const category: string = activeCategory || "";
        const menu_id: string = menu?.id.toString() || "";
        const menu_name: string = menu?.name || "";
        Analytics.logDishVisit(selectedDish?.name ?? '', category, menu_name);
    }, [dishId, selectedDish]);

    useEffect(() => {
        const element = document.getElementById("DishPage");
        if (element !== null) {
            element.scrollIntoView({behavior: "smooth"});
        }
    }, []);

    const scrollToCookingProcess = () => {
        const element = document.getElementById("cookingProcces");
        if (element !== null) {
            element.scrollIntoView({behavior: "smooth"});
        }
    };

    const scrollToIngredients = () => {
        const element = document.getElementById("ingredientsBlock");
        if (element !== null) {
            element.scrollIntoView({behavior: "smooth"});
        }
    };

    useEffect(() => {
        dispatch(fetchDishById({dishId: Number(dishId)}));
    }, []);

    return (
        <>
            <View id="DishPage" style={styles.wrapper}>
                <View style={{display: "flex", flexDirection: "column", gap: vw(4)}}>
                    <SingleDishVideo
                        selectedDish={selectedDish}
                        handleScrollToIngredients={scrollToIngredients}
                        handleScrollToCookingProcess={scrollToCookingProcess}
                    />
                    <Ingredients selectedDish={selectedDish as DishType}/>
                    <NutritionValue/>
                    {selectedDish?.funFact && <FunFactComponent/>}
                    <BlockedPage videoUrl={selectedDish?.cookingProcessVideo?.url || null}/>
                </View>
            </View>
        </>
    );
};

const styles = StyleSheet.create({
    wrapper: {
        flex: 1,
        backgroundColor: $black,
    },
});
