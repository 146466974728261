import React from 'react';
import './styles/index.scss'
import { MenuPage } from "../pages/MenuPage/MenuPage";
import { Notification } from "../common/ui-kit/Notification/Notification";
import { SingleDishPage } from "../pages/SingleDishPage/SingleDishPage";
import { AppRoutes } from "../common/component/AppRoutes/AppRoutes";
import styles from "../pages/MenuPage/MenuPage.module.scss";
import {initializeApp} from "firebase/app";

function App() {
  return (
    <div className="app">
      <div>
        <AppRoutes/>
      </div>
    </div>
  );
}

export default App;
