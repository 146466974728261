export enum ScreenEnum {
    MAIN = 'Main',
    INTRODUCTION = 'Introduction',
    MENU = 'Menu',
    FULL_DISH = 'FullDish',
    RESTAURANT_PROFILE = 'RestaurantProfile',
    RESTAURANT_LIST = 'RestaurantsListPage',
    HOME_PAGE = 'HomePage',
    GREETINGS = 'Greetings',
    WELCOME = 'Welcome',
    GEOLOCATION = 'Geolocation',
}

export enum IntroductionStackEnum {
    GREETING_SCREEN = 'GreetingsScreen',
    INTRODUCTION_SCREEN = 'IntroductionScreen',
}
