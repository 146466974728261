export enum MenuDietaryEnum {
    MEAT = 'Meat',
    FISH = 'Keto',
    NO_GLUTEN = 'Gluten-free',
    VEGAN = 'Vegan',
    SPICY = 'Spicy',
    NO_LACTOSE = 'Dairy-free',
    SOY_FREE = 'Soy-free',
    VEGETARIAN = 'Vegetarian',
    KOSHER = 'Koosher',
    HALAL = 'Halal',
}
