import {logEvent} from "firebase/analytics";
import {analytics} from "../config/firebase";
import {Events} from "./types/Events";

export default class Analytics {
    static logEvent(eventName: string, params: {
        [key: string]: string | number
    }) {
        logEvent(analytics, eventName, params);
    }

    static logDishVisit(dishName: string, category: string, menuName: string) {
        this.logEvent(Events.DISH_VISIT, {
            dish_name: dishName,
            category,
            menu_name: menuName,
        });
    }

    static logMenuVisit(menu_id: string, timeSpentInMs: number) {
        const durationInSeconds: number = Math.floor(timeSpentInMs / 1000);
        if (durationInSeconds > 1) {
            console.log("dasdasda");
            this.logEvent(Events.MENU_VISIT, {
                menu_id: menu_id,
                time_spent_sec: durationInSeconds
            });
        }
    }
}
