export const $black = '#000000';
export const $white = '#FFFFFF';
export const $midnightBlack = '#1C1C1C';
export const $midnightGray = '#2B2B2B';

export const $semiTransparentWhite = 'rgba(255, 255, 255, 0.40)';
export const $semiTransparentGray = 'rgba(34, 34, 34, 0.60)';

export const $darkGray = '#1B1B1B';
export const $lightGray = '#9D9D9D';
export const $lightSteelGray = '#AAA';
export const $gray = '#CCCCCC';


export const $SFProText700 = 'SFProText-700';
export const $SFProText600 = 'SFProText-600';
export const $SFProText500 = 'SFProText-500';
export const $SFProText400 = 'SFProText-400';
export const $SFProDisplay700 = 'SFProDisplay-700';
export const $SFProDisplay600 = 'SFProDisplay-600';
export const $Schoolbook = 'Schoolbook-500';
export const $SchoolbookItalic = 'Schoolbook-italic';
export const $Schoolbook700 = 'Schoolbook-700';
