import {vw} from "../../../../utils/tool/vw";
import {Image, Pressable, StyleSheet, Text, View} from "react-native-web";
import {ReactComponent as List} from "../../../../assets/icons/list.svg";
import React, {FC, useState} from "react";
import {IngredientsData} from "./ingridientsData";
import {$darkGray, $SFProText400, $white} from "../../../constants/style";
import {Modal} from "../../../ui-kit/Modal/Modal";
import {DishType} from "../../../types/ResponseType";
import {useAppSelector} from "../../../../utils/hook/redux-hooks";
import {Collapse} from "@mui/material";


interface IProps {
    selectedDish: DishType
}
export const Ingredients: FC<IProps> = ({selectedDish}) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const ingredients = selectedDish?.ingredients;
    const firstIngredients = ingredients?.filter((item, index) => item.image);
    const lastIngredients = ingredients?.filter((item, index) => !item.image);


    return (
        <>
            <View id='ingredientsBlock' style={styles.ingredientsBlock}>
                <View style={styles.ingredientsHeader}>
                    <List width={vw(38)} height={vw(38)}/>
                    <Text style={styles.ingredientsHeaderText}>Ingredients</Text>
                </View>
                <View style={styles.ingredientsList}>
                    {firstIngredients?.map((item, index) => {
                        return (
                            <View key={`${index + item.name}`} style={styles.ingredientsItem}>
                                <Image
                                    source={item.image?.url}
                                    style={{width: vw(95), height: vw(96)}}
                                />
                                <Text style={styles.ingredientsText}>{item.name}</Text>
                            </View>
                        );
                    })}
                </View>
                <View style={{width: '100%'}}>
                    <Collapse in={isCollapsed}>
                        {
                            lastIngredients?.map((item, index) => {
                                const isLastItem = index === lastIngredients.length - 1;
                                const itemStyle = isLastItem ? [styles.ingredientsLastItem, {borderBottomWidth: 0}] : styles.ingredientsLastItem;


                                return (
                                    <View key={`${index + item.name}`} style={styles.ingredientsLastList}>
                                        <View style={itemStyle}>
                                            <Text style={styles.itemsTitle}>{item.name}</Text>
                                        </View>
                                    </View>
                                );
                            })
                        }
                    </Collapse>
                </View>
                <View style={styles.showButtonContainer}>
                    {
                        lastIngredients?.length > 0 &&
                        <Pressable onPress={() => setIsCollapsed(!isCollapsed)} style={styles.showButton}>
                            <Text style={styles.buttonTitle}>{isCollapsed ? 'Show less' : 'Show more'}</Text>
                        </Pressable>
                    }
                </View>
            </View>
            {/*<Modal open={openModal} setOpenModal={setOpenModal}/>*/}
        </>
    );
};

const styles = StyleSheet.create({
    ingredientsBlock: {
        display: "flex",
        paddingVertical: vw(32),
        paddingHorizontal: vw(16),
        flexDirection: "column",
        alignItems: "flex-start",
        gap: vw(16),
        borderRadius: 32,
        backgroundColor: $darkGray
    },
    ingredientsHeader: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: vw(12)
    },
    ingredientsHeaderText: {
        color: $white,
        fontSize: vw(20),
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: vw(25),
        letterSpacing: 0.38
    },
    ingredientsText: {
        color: $white,
        textAlign: "center",
        fontSize: vw(12),
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: vw(16)
    },
    ingredientsList: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "flex-start",
        alignContent: "flex-start",
        rowGap: vw(24),
        flexWrap: "wrap"
    },
    ingredientsItem: {
        display: "flex",
        width: vw(106),
        flexDirection: "column",
        alignItems: "center",
        gap: vw(8)
    },
    showButtonContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: vw(6),
        alignSelf: "stretch"
    },
    showButton: {
        display: "flex",
        padding: vw(8),
        paddingHorizontal: vw(16),
        justifyContent: "center",
        alignItems: "center",
        gap: vw(10),
        borderRadius: 999,
        borderWidth: vw(1),
        borderColor: "rgba(255, 255, 255, 0.40)"
    },
    buttonTitle: {
        color: $white,
        textAlign: "center",
        fontSize: vw(13),
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: vw(18),
        letterSpacing: -0.078
    },
    ingredientsLastList: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    ingredientsLastItem: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: vw(6),
        paddingVertical: vw(12),
        borderBottomColor: 'rgba(255, 255, 255, 0.30)',
        borderBottomWidth: vw(1),
    },
    itemsTitle: {
        color: $white,
        // textAlign: "center",
        fontSize: vw(15),
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: vw(16)
    },
});
