import styles from './BlockedPage.module.scss'
import {ReactComponent as Logo} from '../../../../assets/image/logo.svg'
import {ReactComponent as Lock} from '../../../../assets/icons/lock.svg'
// @ts-ignore
import CookingProcess from  '../../../../assets/video/cookingProcess.mp4'

export const BlockedPage = ({videoUrl}: { videoUrl: string | null }) => {
    const video = videoUrl || CookingProcess

    return <div id='cookingProcces' className={styles.container}>
        <div className={styles.background}></div>
        <video
            src={video}
            className={styles.video}
            autoPlay
            muted
            loop
            playsInline
        >
            sfdsf
        </video>

        <div className={styles.content}>
            <div className={styles.main}>
                <div className={styles.logo}>
                    <Logo/>
                </div>
                <div className={styles.title}>
                    <h4>Unlock more features when our app is ready</h4>
                </div>
                <div className={styles.blockedItems}>
                    {/*<div className={styles.blockedItem}>*/}
                    {/*    <Lock width={18} height={18}/>*/}
                    {/*    <p className={styles.blockedItemText}>Nutrition value</p>*/}
                    {/*</div>*/}
                    <div className={styles.blockedItem}>
                        <Lock width={18} height={18}/>
                        <p className={styles.blockedItemText}>Сooking process</p>
                    </div>
                </div>
            </div>
            {/*<div className={styles.buttonBlock}>*/}
            {/*    <button className={styles.button}>Open AppStore</button>*/}
            {/*</div>*/}
        </div>

    </div>;
};
