import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
	apiKey: "AIzaSyBrv1tcjdN9L8-A53fs6Ze1p-147KG0Ftg",
	authDomain: "thatlooksgood-nyc.firebaseapp.com",
	projectId: "thatlooksgood-nyc",
	storageBucket: "thatlooksgood-nyc.appspot.com",
	messagingSenderId: "1051549448077",
	appId: "1:1051549448077:web:e8c558d7747e6da2cb2fc4",
	measurementId: "G-9ECMP3CPGR"
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export default app;