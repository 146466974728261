import { StyleSheet, Text, View } from "react-native-web"
import { ReactComponent as Fire } from '../../../../assets/icons/Fire.svg'
import {$Schoolbook} from "../../../constants/style";
import {useAppSelector} from "../../../../utils/hook/redux-hooks";
import {vw} from "../../../../utils/tool/vw";

export const FunFactComponent = () =>{
    const {selectedDish} = useAppSelector(state => state.menu)
    return(

        <View>
            <View style={styles.funFactContainer}>
                <View style={styles.titleContainer}>
                    <Fire/>
                    <Text style={styles.funFactsTitle}>
                        Fun Fact
                    </Text>
                </View>

                <Text style={styles.funFactsContent}>
                    {selectedDish?.funFact.trim()}
                </Text>

                {/*<View style={styles.indicatorContainer}>*/}
                {/*    <View style={[styles.indicator, styles.activeIndicator]} />*/}
                {/*    <View style={styles.indicator} />*/}
                {/*    <View style={styles.indicator} />*/}
                {/*</View>*/}
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    funFactContainer: {
        backgroundColor: '#1B1B1B',
        borderRadius: 32,
        width: '100%',
        // minHeight: vw(369),
    },
    funFactsTitle: {
        fontSize: vw(20),
        alignSelf: 'flex-start',
        color: 'white',
        marginLeft: 14
    },
    titleContainer: {
        flexDirection: 'row',
        marginTop: vw(32),
        marginLeft: vw(16),
    },
    funFactsContent: {
        fontSize: vw(17),
        textAlign: 'center',
        color: 'white',
        paddingTop: vw(34),
        paddingBottom: vw(64),
        paddingHorizontal: vw(44),
        // fontFamily: $Schoolbook,
        fontWeight: '400',
        fontStyle: 'normal',
    },
    indicatorContainer: {
        flexDirection: "row",
        justifyContent: "center",
        marginTop: vw(24),
    },
    indicator: {
        width: vw(6),
        height: vw(6),
        borderRadius: vw(5),
        backgroundColor: "gray",
        marginHorizontal: vw(5),
    },
    activeIndicator: {
        backgroundColor: "white",
        width: vw(18),
    },
});
