import {Pressable, StyleSheet, Text, View} from "react-native-web";
import {vw} from "../../../../utils/tool/vw";
import {ReactComponent as Leaf }from '../../../../assets/icons/leaf.svg';
import React, {useEffect, useState} from "react";
import {$darkGray, $SFProText400, $SFProText600, $white} from "../../../constants/style";
import {VerticalScale} from "../../../ui-kit/VerticalScale";
import {useAppSelector} from "../../../../utils/hook/redux-hooks";
import {Collapse} from "@mui/material";

export const NutritionValue = () => {
    const {selectedDish} = useAppSelector(state => state.menu)
    const [isCollapsed, setIsCollapsed] = useState(false)
    // let proteinValue: number
    const [proteinValue, setProteinValue] = useState(0)
    const [fatsValue, setFatsValue] = useState(0)
    const [carbValue, setCarbValue] = useState(0)


    // const calculatePercentage = (protein: number, fats: number, carb: number) => {
    //     const total = protein + fats + carb;
    //     proteinValue = Number((protein / total * 100).toFixed(2))
    //     fatsValue = Number((fats / total * 100).toFixed(2))
    //     carbValue = Number((carb / total * 100).toFixed(2))
    // }

    useEffect(() => {
        if (selectedDish) {
            const protein = parseFloat(selectedDish?.protein);
            const fats = parseFloat(selectedDish?.fats);
            const carbohydrates = parseFloat(selectedDish?.carbohydrates);

            const total = protein + fats + carbohydrates;

            setProteinValue(Number(((protein / total) * 100).toFixed(2)) + 20);
            setFatsValue(Number(((fats / total) * 100).toFixed(2)) + 20);
            setCarbValue(Number(((carbohydrates / total) * 100).toFixed(2)));
        }
    }, [proteinValue, fatsValue, carbValue, selectedDish])

    return (
        <View style={styles.wrapper}>
            <View style={styles.header}>
                <Leaf/>
                <Text style={styles.headerTitle}>Nutrition value</Text>
            </View>
            <View style={styles.items}>

                <View style={styles.item}>
                    <VerticalScale filledPercentage={proteinValue} color='#95B35D'/>
                    <View style={styles.itemTextBlock}>
                        <Text style={styles.ItemValue}>{selectedDish?.protein?.split('-')[0]} g</Text>
                        <Text style={styles.itemsTitle}>Protein</Text>
                    </View>
                </View>
                <View style={styles.item}>
                    <VerticalScale filledPercentage={carbValue} color='#D3B062'/>
                    <View style={styles.itemTextBlock}>
                        <Text style={styles.ItemValue}>{selectedDish?.carbohydrates.split('-')[0]} g</Text>
                        <Text style={styles.itemsTitle}>Carb</Text>
                    </View>
                </View>
                <View style={styles.item}>
                    <VerticalScale filledPercentage={fatsValue} color='#DC6A65'/>
                    <View style={styles.itemTextBlock}>
                        <Text style={styles.ItemValue}>{selectedDish?.fats.split('-')[0]} g</Text>
                        <Text style={styles.itemsTitle}>Fats</Text>
                    </View>
                </View>
                <View style={[styles.item, {marginLeft: vw(12)}]}>
                    <View style={styles.itemTextBlock}>
                        <Text style={styles.itemsTitle}>{selectedDish?.calories.replace("-", " - ")}</Text>
                        <Text style={styles.itemsTitle}>Calories</Text>
                    </View>
                </View>


            </View>
            <View style={{width: '100%'}}>
                <Collapse in={isCollapsed}>
                    {selectedDish?.protein &&
                        <View style={styles.nutritionList}>
                            <View style={styles.nutritionItem}>
                                <Text style={styles.itemsTitle}>Protein</Text>
                                <Text style={styles.itemsTitle}>{selectedDish.protein.replace("-", " - ")} g</Text>
                            </View>
                        </View>
                    }
                    {selectedDish?.fats &&
                        <View style={styles.nutritionList}>
                            <View style={styles.nutritionItem}>
                                <Text style={styles.itemsTitle}>Fats</Text>
                                <Text style={styles.itemsTitle}>{selectedDish?.fats.replace("-", " - ")} g</Text>
                            </View>
                        </View>
                    }
                    {selectedDish?.carbohydrates &&
                        <View style={styles.nutritionList}>
                            <View style={styles.nutritionItem}>
                                <Text style={styles.itemsTitle}>Carbs</Text>
                                <Text style={styles.itemsTitle}>{selectedDish?.carbohydrates.replace("-", " - ")} g</Text>
                            </View>
                        </View>
                    }
                    {selectedDish?.sugar &&
                        <View style={styles.nutritionList}>
                            <View style={styles.nutritionItem}>
                                <Text style={styles.itemsTitle}>Sugar</Text>
                                <Text style={styles.itemsTitle}>{selectedDish?.sugar.replace("-", " - ")} g</Text>
                            </View>
                        </View>
                    }
                    {selectedDish?.cellulose &&
                        <View style={styles.nutritionList}>
                            <View style={styles.nutritionItem}>
                                <Text style={styles.itemsTitle}>Cellulose</Text>
                                <Text style={styles.itemsTitle}>{selectedDish?.cellulose.replace("-", " - ")} g</Text>
                            </View>
                        </View>
                    }
                </Collapse>
            </View>
            <View style={styles.button}>
                <Pressable style={styles.showButton} onPress={() => setIsCollapsed(!isCollapsed)}>
                    <Text style={styles.buttonTitle}>{!isCollapsed ? 'Show Details' : 'Show Less'}</Text>
                </Pressable>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        width: '100%',
        display: 'flex',
        paddingVertical: vw(32),
        paddingHorizontal: vw(16),
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: vw(16),
        borderRadius: 32,
        backgroundColor: $darkGray,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: vw(12),
    },
    headerTitle: {
        color: $white,
        fontSize: vw(20),
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: vw(25),
        letterSpacing: 0.38
    },
    items: {
        display: 'flex',
        flexDirection: 'row',
        paddingVertical: vw(12),
        alignItems: 'flex-end',
        gap: vw(30),
        alignSelf: 'stretch',
        // backgroundColor: '#b40505',
    },
    item: {
        flexDirection: 'row',
        gap: vw(6)
    },
    itemTextBlock: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: vw(4),
    },
    itemsTitle: {
        color: $white,
        textAlign: "start",
        fontSize: vw(15),
        fontWeight: "400",
        lineHeight: vw(18),
        maxWidth: vw(100),
    },
    ItemValue: {
        color: $white,
        textAlign: "center",
        fontSize: vw(16),
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: vw(16)
    },
    nutritionList: {
        display: 'flex',
        flexDirection: 'column',
    },
    nutritionItem: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: vw(6),
        paddingVertical: vw(12),
        borderBottomColor: 'rgba(255, 255, 255, 0.30)',
        borderBottomWidth: vw(1),
    },
    button: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: vw(6),
        alignSelf: 'stretch',
    },
    showButton: {
        display: 'flex',
        padding: vw(8),
        paddingHorizontal: vw(16),
        justifyContent: 'center',
        alignItems: 'center',
        gap: vw(10),
        borderRadius: 999,
        borderWidth: vw(1),
        borderColor: 'rgba(255, 255, 255, 0.40)',
    },
    buttonTitle: {
        color: $white,
        textAlign: "center",
        fontSize: vw(13),
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: vw(18),
        letterSpacing: -0.078
    },
})
