import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {cmsInstance} from "../../config/backend";
import {DishType, MenuType} from "../../common/types/ResponseType";


interface Params {
    dishId: number
}

export const fetchMenu = createAsyncThunk<MenuType, { id: number }>(
    'menu/fetchMenu',
    async (params) => {
        try {
            const {data} = await cmsInstance.get(`/menu/${params.id}`);
            return data;
        } catch (error) {
            console.error(error);
            return [];
        }
    }
);

export const fetchDishById = createAsyncThunk(
    'menu/fetchDishesById',
    async (payload: Params) => {
        try {
            const {data} = await cmsInstance.put(`/menu/dish/${payload.dishId}`);
            return data;
        } catch (error) {
            console.log(error);
            return null;
        }
    }
);

type initialStateType = {
    menu: MenuType | null;
    selectedDish: DishType | null;
    activeCategory: string | null;
    activeDietaries: string[];
    loading: boolean;
    categoryLoading: boolean;
    error: string | null;
};

const initialState: initialStateType = {
    menu: null,
    selectedDish: null,
    activeCategory: null,
    activeDietaries: [],
    loading: false,
    categoryLoading: false,
    error: null,
};

const menuSlice = createSlice({
    name: 'restaurant',
    initialState,
    reducers: {
        clearSelectedDish: (state) => {
            state.selectedDish = null;
        },
        setActiveCategory: (state, action: PayloadAction<string>) => {
            console.log('action.payload', action.payload)
            state.activeCategory = action.payload;
        },
        setActiveDietaries: (state, action: PayloadAction<string[]>) => {
            state.activeDietaries = action.payload;
        },
        startLoading: (state) => {
            state.categoryLoading = true;
        },
        stopLoading: (state) => {
            state.categoryLoading = false;
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMenu.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchMenu.fulfilled, (state, action: PayloadAction<MenuType>) => {
                state.menu = action.payload;
                state.loading = false;
            })
            .addCase(fetchMenu.rejected, (state, action) => {
                state.error = action.error.message || 'Error loading dish data';
                state.loading = false;
            })
            .addCase(fetchDishById.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchDishById.fulfilled, (state, action: PayloadAction<DishType>) => {
                state.selectedDish = action.payload;
                state.loading = false;
            })
            .addCase(fetchDishById.rejected, (state, action) => {
                state.error = action.error.message || 'Error loading dish data';
                state.loading = false;
            });
    },
});

export const {clearSelectedDish, setActiveCategory, setActiveDietaries, startLoading, stopLoading} = menuSlice.actions;
export default menuSlice.reducer;
