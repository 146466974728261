export enum IndicatorEnum {
    PRICE,
    PRICE_THREE_DOLLARS,
    DISTANCE,
    RATING,
    CATEGORY,
    TIME,
    SITE,
    MENU,
    RESTAURANT,
    FEATURED,
}
